/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

export const Header = (props) => {
  const [displayText, setDisplayText] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);

  useEffect(() => {
    const text = props.data ? props.data.title : "Loading";
    const interval = setInterval(() => {
      setDisplayText(text.substring(0, typingIndex));
      setTypingIndex((prevIndex) => prevIndex + 1);
    }, 150); // Adjust typing speed here (milliseconds)

    return () => clearInterval(interval);
  }, [props.data, typingIndex]);

  return (
    <header id="header" style={{ overflow: "hidden" }}>
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1 className="">
                  {displayText}
                  <span className="blinking-cursor"></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bgdlsd">
      </div> */}
    </header>

    // <section class="headerCustom">
    //   <div class="container mx-auto px-4">
    //     <div class="flex flex-col md:flex-row items-center justify-between">
    //       <div class="md:w-1/2 mb-8 md:mb-0">
    //         <h1 class="text-white font-bold text-5xl leading-tight mb-6">
    //           Discover the world's best coffee
    //         </h1>
    //         <p class="text-white text-xl mb-8">
    //           From hand-picked farms to your cup, we source the finest beans and
    //           roast them to perfection.
    //         </p>
    //         <a
    //           href="#"
    //           className="px-6 py-3 bg-white text-blue-600 font-bold rounded-full hover:bg-blue-700 transition duration-200"
    //         >
    //           Shop now
    //         </a>
    //       </div>
    //       <div class="md:w-1/2">
    //         <img
    //           src="https://plus.unsplash.com/premium_photo-1675716443562-b771d72a3da7"
    //           alt="Coffee beans"
    //           class="w-full rounded-lg shadow-lg"
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </section>

    // <section className="headerCustom">
    //   <p>
    //     lorem250dffffffffffffffffffffffffffffffffffffffffffffffffffffffffffdasssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss
    //   </p>
    // </section>
  );
};
