/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import NHAI from "../Assests/clients/NHAI-Color.svg";
// import PUNBUS from "../Assests/clients/punbus-logo.png";
import PUNBUS from "../Assests/clients/punbus.jpg";
import Deltron from "../Assests/clients/deltron-logo.jpg";
// import HindustanTimes from "../Assests/clients/hindustan-times9271.jpg";
import HindustanTimes from "../Assests/clients/hindustan-times_cropped.jpg";
import HMT from "../Assests/clients/HMT.png";
import KDDL from "../Assests/clients/KDDL.png";
import MICRON from "../Assests/clients/micron.png";
// import PUNBUS from "../Assests/clients/punbus-logo.png";
import SIGMA from "../Assests/clients/sigma.svg";
import TATA_TELI from "../Assests/clients/Tata_teli.svg";
import UTI from "../Assests/clients/uti-new-logo.png";

export const Team = (props) => {
  return (
    <>
      <div id="team">
        <div className="container" style={{ overflow: "hidden" }}>
          <div className="text-center section-title">
            <h2 data-aos="fade-up">Our Clients</h2>
            <p data-aos="fade-down">
              Our Successfull Business Partnerships and projects with our
              esteemed clients.
            </p>
          </div>
          <div id="row">
            <div class="logos container">
              <div class="logo_items">
                <img
                  src={NHAI}
                  style={{ width: "199px", height: "70px", padding: "0px" }}
                />
                <img
                  src={Deltron}
                  style={{ width: "189px", height: "80px", padding: "0px" }}
                />
                <img
                  src={HindustanTimes}
                  style={{ width: "269px", height: "103px" }}
                />
                <img src={HMT} />
                <img
                  src={KDDL}
                  style={{ width: "199px", height: "70px", padding: "0px" }}
                />
                <img
                  src={MICRON}
                  style={{
                    width: "199px",
                    height: "70px",
                    padding: "0px",
                    background: "black",
                  }}
                />
                <img
                  src={PUNBUS}
                  style={{ width: "199px", height: "70px", padding: "0px" }}
                />
                <img
                  src={SIGMA}
                  style={{ width: "199px", height: "70px", padding: "0px" }}
                />
                <img
                  src={TATA_TELI}
                  style={{
                    width: "199px",
                    height: "70px",
                    padding: "0px",
                    background: "black",
                  }}
                />
                <img
                  src={UTI}
                  style={{ width: "199px", height: "70px", padding: "0px" }}
                />
                <img
                  src={NHAI}
                  style={{ width: "199px", height: "70px", padding: "0px" }}
                />
                <img
                  src={Deltron}
                  style={{ width: "189px", height: "80px", padding: "0px" }}
                />
                <img
                  src={HindustanTimes}
                  style={{ width: "269px", height: "103px" }}
                />
                <img src={HMT} />
                <img
                  src={KDDL}
                  style={{ width: "199px", height: "70px", padding: "0px" }}
                />
                <img
                  src={MICRON}
                  style={{
                    width: "199px",
                    height: "70px",
                    padding: "0px",
                    background: "black",
                  }}
                />
                <img
                  src={PUNBUS}
                  style={{ width: "199px", height: "70px", padding: "0px" }}
                />
                <img
                  src={SIGMA}
                  style={{ width: "199px", height: "70px", padding: "0px" }}
                />
                <img
                  src={TATA_TELI}
                  style={{
                    width: "199px",
                    height: "70px",
                    padding: "0px",
                    background: "black",
                  }}
                />
                <img
                  src={UTI}
                  style={{ width: "199px", height: "70px", padding: "0px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
