import React, { useState } from "react";
import logo from "../Assests/Img/Lehri_logo.svg";

export const Navigation = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleNavbarItemClick = () => {
    setIsCollapsed(true);
    console.log(setIsCollapsed(true));
  };

  return (
    <nav
      id="menu"
      className="navbar navbar-default navbar-fixed-top"
      style={{ overflow: "hidden", width: "100vw" }}
    >
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            // data-toggle="collapse"
            // data-target="#bs-example-navbar-collapse-1"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll bg-slate-600" href="#page-top">
            <img
              width="230px"
              height="auto"
              src={logo}
              className="custom-logo svg-logo-image"
              alt="Top Hub Security"
              decoding="async"
            />
          </a>{" "}
        </div>

        <div
          className={`collapse navbar-collapse ${isCollapsed ? "" : "in"}`}
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {/* <li onClick={handleNavbarItemClick}>
              <a href="#page-top" className="page-scroll">
                Home
              </a>
            </li> */}
            <li onClick={handleNavbarItemClick}>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li onClick={handleNavbarItemClick}>
              <a href="#services" className="page-scroll">
                Services
              </a>
            </li>
            <li onClick={handleNavbarItemClick}>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
