import React from "react";
import award1 from "../Assests/Awards/ISO9001-stamp.png";
import award2 from "../Assests/Awards/Iso_Environment.jpg";
import award3 from "../Assests/Awards/iso-20000-1-2011-certification-service.png";
import award4 from "../Assests/Awards/iso-45001-2018-certification-services-500x500.jpg";
import award5 from "../Assests/Awards/sa-8000-certifications-audit.png";

const AwardsRecognition = () => {
  return (
    <>
      <div id="team">
        <div className="container" style={{ overflow: "hidden" }}>
          <div className="text-center section-title">
            <h2 data-aos="zoom-in-up">Certifications</h2>
            <p data-aos="zoom-in-down">
              Discover our Rigorous certification and accreditation processes
              ensuring quality standards and compliance.
            </p>
          </div>
          <div>
            <div class="content ">
              <div class="card block " data-aos="fade-up-right">
                <img src={award1} alt="ds" height={"133px"} />
              </div>
              <div
                class="card block"
                data-aos="zoom-in-up"
                data-aos-delay="500"
              >
                <img src={award2} alt="ds" height={"133px"} />
              </div>
              <div
                class="card block"
                data-aos="zoom-in-down"
                data-aos-delay="900"
              >
                <img src={award3} alt="ds" height={"160px"} />
              </div>
              <div
                class="card block"
                data-aos="zoom-in-up"
                data-aos-delay="1300"
              >
                <img src={award4} alt="ds" height={"133px"} />
              </div>
              <div
                class="card block"
                data-aos="fade-up-right"
                data-aos-delay="1700"
              >
                <img src={award5} alt="ds" height={"133px"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AwardsRecognition;
