import React from "react";

export const Services = (props) => {
  const getAnimation = (index) => {
    switch (index) {
      case 0:
        return "fade-up-right";
      case 1:
        return "zoom-in-up";
      case 2:
        return "fade-up-left";
      default:
        return "fade-up"; // Default animation
    }
  };

  return (
    <div id="services" className="text-center">
      <div className="container" style={{ overflow: "hidden" }}>
        <div className="section-title">
          <h2 data-aos="zoom-in-up">Our Services</h2>
          <p data-aos="zoom-in-down">
            Lehri Solutions offers the following services detailed below at an
            affordable monthly cost.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.name}-${i}`}
                  className="col-md-4"
                  data-aos={getAnimation(i)}
                >
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text1}</p>
                    <p>{d.text2}</p>
                    <p>{d.text3}</p>
                    <ul>
                      {d.points.map((item, index) => (
                        <li
                          style={{ justifyContent: "start", color: "black" }}
                          key={index}
                        >
                          <span style={{ color: "#777777" }}>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
