import React from "react";

export const About = (props) => {
  return (
    // <div id="about">
    //   <div className="container">
    // <div className="row">
    //   <div className="col-sm-6 col-md-6 col-lg-6">
    //     <div className="about-section">
    //       <img src="img/pic.jpg" className="img-responsive" alt="" />
    //     </div>
    //   </div>
    //   <div className="col-sm-6 col-md-6 col-lg-6">
    //     <div className="about-text" data-aos="fade-left">
    //       <h2>About us</h2>
    //       <p>{props.data ? props.data.paragraph : "loading..."}</p>
    //     </div>
    //   </div>
    // </div>
    //   </div>
    // </div>
    <div id="about">
      <div className="container">
        <div className="okabout" data-aos="fade-up">
          <h2>About us</h2>
        </div>
        <div className="abc">
          <div className="abc2">
            <div className="about-section img-responsive" data-aos="fade-right">
              <img
                src="img/pic.jpg"
                className=""
                alt=""
                style={{ margin: 0 }}
              />
            </div>
          </div>
          <div className="abc2" style={{ overflow: "hidden" }}>
            <div className="about-text" data-aos="fade-left">
              <p className="about-p">
                {props.data ? props.data.paragraph1 : "loading..."}
              </p>
              <p className="about-p">
                {props.data ? props.data.paragraph2 : "loading..."}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
